// Styles
// CoreUI Icons Set

@import '~@coreui/icons/css/all.css';
@import '~@coreui/icons/css/flag.css';
// Import Flag Icons Set
// Import Font Awesome Icons Set
@import '~font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
@import '~simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
@import './scss/style.scss';

.hover-darkness:hover{
  background: #0001;
}
@media (max-width: 768px) {
  .d-md-down-none{
    display: none;
  }
  .md-absolute-center{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}
.dlg-highlited{
  background: #0088ff1f;
}
.inner-visible > ul{
  display: block !important;
  height: 125px !important;
}.isFour > ul{
  display: block !important;
  height: 168px !important;
}
.inner-invisible > ul{
  display: block !important;
  height: 0 !important;
}

.inner-invisible{
  background: #0001;
}
.sidebar-header{
  display: none;
}
.dlg-alert-materia{
  display: flex;
  align-items: center;
  gap: 12px;

  background-color: #fdcda5;
  padding: 10px;
  color: #9d5503;
  border-radius: 8px;
}
.child-max-content > div{
  width: max-content;
}
.flex-col{
  flex-direction: column;
}
ul.dlg-spacing-12 li{
  margin-top: 8px;
  margin-bottom: 8px;
}
.masterCalendar .fc-toolbar-title{
  display: none;
}
.masterCalendar .btn-group{
  overflow: hidden;
}
.hideArrow .fc-next-button, .hideArrow .fc-prev-button{
  display: none;
}
.hideNext .fc-next-button, .hidePrev .fc-prev-button{
  display:none;
}
.masterCalendar .fc-col-header-cell-cushion {
  text-transform: capitalize;
}
