@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';
@import '~@fullcalendar/list/main.css';
@import '~@fullcalendar/bootstrap/main.css';

.btn, .btn-group {
  --cui-btn-border-radius: 10px
}

.btn-group {
  border-radius: var(--cui-btn-border-radius);
}

.sidebar-nav .nav-link {
  --cui-sidebar-nav-link-padding-y: 0.65rem !important;
}

.app-root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.CalendarDiv {
  overflow: hidden;

  .fc.fc-media-screen.fc-theme-bootstrap.fc-liquid-hack, .fc-timegrid.fc-timeGridWeek-view.fc-view, .CalendarDiv .fc-scrollgrid-section.fc-scrollgrid-section-body.fc-scrollgrid-section-liquid {
    overflow-x: scroll;
  }
}

.fc-timegrid-event-harness a, .fc-timegrid-event-harness, .fc-timegrid-event-harness .fc-timegrid-event {
  overflow: visible !important;
}

.fc-event-main {
  overflow: visible !important;
}

.fc-timegrid-event-harness .fc-timegrid-event .gray {
  background-color: #e3e3e3dd !important;
  outline: 2px solid #e3e3e3dd;
}

.CalendarDiv .fc-scroller.fc-scroller-liquid-absolute {
  overflow: hidden;

  .fc-event-time {
    display: none;
  }

  .fc-day-sun {
    display: none;
  }
}

.fc-day-sun {
  display: none;
}

.CalendarDiv .fc-daygrid .fc-scroller.fc-scroller-liquid-absolute {
  margin-top: -0px;
  overflow: hidden;
}

.CalendarDiv .fc .fc-view-harness-active > .fc-view {
  position: static !important;
}

.fc.fc-media-screen.fc-theme-bootstrap.fc-liquid-hack,
.fc-timegrid.fc-timeGridWeek-view.fc-view, .CalendarDiv .fc-scrollgrid-section.fc-scrollgrid-section-body.fc-scrollgrid-section-liquid {
  height: 100% !important;
}

.CalendarDiv .fc .fc-view-harness-active > .fc-view {
  height: 100%;
}

.fc-toolbar-title {
  text-transform: capitalize;
}

.fc-daygrid-event.fc-daygrid-dot-event.fc-event.fc-event-start.fc-event-end {
  overflow: hidden;
}

.CalendarCardBody {
  overflow: hidden;
  max-height: 700px;
  //overflow: scroll;
}

.app-header {
  flex: 0 0 64px;
}

.app-footer {
  flex: 0 0 64px;
}

.app-body {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow-x: hidden;

  .main {
    flex: 1;
    min-width: 0;
  }


  .aside-menu {
    // $aside-menu-width is the width of the columns
    flex: 0 0 256px;
  }
}

.app-body {
  display: flex;

}

ul.ml-auto.navbar-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;
  align-items: center;
  margin: auto;
  margin-right: 24px;
}

.header {
  padding: 0;
  position: sticky;
  top: 0;
  z-index: 2;
  height: 64px;
  align-items: baseline;
  margin-bottom: 10px;
}

.navbar-brand {
  width: 228px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 55px;
}

.sidebar-toggler {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
}

.fc.fc-bootstrap a {
  text-decoration: none;
}

.fc.fc-bootstrap a[data-goto]:hover {
  text-decoration: underline;
}

.fc-bootstrap hr.fc-divider {
  border-color: inherit;
}

.fc-bootstrap .fc-today.alert {
  border-radius: 0;
}

.fc-bootstrap a.fc-event:not([href]):not([tabindex]) {
  color: #fff;
}

.fc-bootstrap .fc-popover.card {
  position: absolute;
}

/* Popover
--------------------------------------------------------------------------------------------------*/
.fc-bootstrap .fc-popover .card-body {
  padding: 0;
}

/* TimeGrid Slats (lines that run horizontally)
--------------------------------------------------------------------------------------------------*/
.fc-bootstrap .fc-time-grid .fc-slats table {
  /* some themes have background color. see through to slats */
  background: none;
}

$theme-colors: (
        "primary": #0074d9,
        "danger": #ff4136
);
//.btn {
//  --cui-btn-disabled-bg:#000 !important;
//}
.btn {
  --cui-btn-bg: #20a8d8;
  --cui-btn-active-bg: #006b9f;
  --cui-btn-hover-bg: #006b9f;
  --cui-btn-border-color: none;
  --cui-btn-disabled-border-color: none;
  --cui-btn-disabled-bg: #97dbff;
  --cui-btn-danger-bg: #ff5656;
}

:root {
  --cui-border-color: #e4e7ea
}

.rw-widget-input, .rw-widget-picker, .form-control {
  border-color: #e4e7ea !important;
}

.rw-widget-input:focus, .rw-widget-picker:focus, .form-control:focus {
  border-color: rgba(32, 168, 216, 0.5) !important;
  box-shadow: 0 0 3px 2px rgba(32, 168, 216, 0.25) !important;
}

.form-select {
  --cui-form-select-border-color: #e4e7ea;
  --cui-form-select-focus-border-color: rgba(32, 168, 216, 0.5)

}

.form-select:focus {
  box-shadow: 0 0 0 0.25rem rgba(32, 168, 216, 0.25)
}

.btn-link {
  background: transparent !important;
  border: none
}

.btn-danger {
  background: var(--cui-btn-danger-bg);
  border-color: #f00;
  color: white;
}

body {
  display: flex;
  height: 100%;
  flex-direction: column;
}

:root {
  font-size: 0.93em;
}

.btn-warning {
  color: #23282c;
  background-color: #ffc107;
  border-color: #ffc107;
}

body {
  background: #e4e5e6;
}

.cursor-pointer {
  cursor: pointer;
}

.hover-shadow-elevate {
  transition: 0.2s;
}

.hover-shadow-elevate:hover {
  box-shadow: 1px 1px 2px black;
}

.fc-timegrid-event:hover {
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.37) !important;
  z-index: 20000;
}

.fc-timegrid-event {
  cursor: pointer;

}

.fc-event-main {
  overflow: hidden;
}

.fc-timegrid-event-harness {
  background: white;
}

.fc-timegrid-event-harness:hover {
  //z-index: 200 !important;
}

.shadow-inset {
  box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.21);
}

.points .react-tabs__tab:focus::after {
  display: none;
}

.l-h-35 {
  line-height: 35px;
}

//
.table-no-overflow-x > .table-responsive {
  overflow-x: hidden;
  overflow-y: auto;
}

#calendar-dlg {
  overflow-y: auto;
}

.margin-b-0.mb-3 {
  margin-bottom: 1px !important;
}

.inner-100 > * {
  height: 100%;
}

.input-text-button {
  background: #5fbfe3;
  color: white;
  cursor: pointer;
}

.input-text-button-active {
  background: #0af;
  color: white;
  cursor: pointer;
}

.input-text-button:hover, .input-text-button-active:hover {
  background: #5fa1e3;
}

.input-cross-button {
  background: #e35f5f;
  color: white;
  cursor: pointer;
}

.input-cross-button-active {
  background: #ff0000;
  color: white;
  cursor: pointer;
}

.input-cross-button:hover {
  background: #c42e2e;
}

.dlg-table .table-responsive {
  border-radius: 16px;
  height: calc(100% - 40px);
  border: 1px solid #cacaca;
  box-shadow: 3px 3px 3px #0002
}

.dlg-table .table th {
  border-top: none;
  border-bottom-width: 1px;
}

.dlg-table thead {
  background: #f7f7f7;
  position: sticky;
  top: 0;
  box-shadow: 0 1px #0006;
}

.success-dlg-button {
  background-color: #29ab29;
  color: white;
}

.success-dlg-button:hover, .success-dlg-button:active, .success-dlg-button:focus {
  background-color: limegreen !important;

}

.error-dlg-button {
  background-color: #c42e2e;
  color: white;
}

.error-dlg-button:hover, .error-dlg-button:active, .error-dlg-button:focus {
  color: white;
  background-color: #ff0000 !important;
}

.warning-dlg-button {
  background-color: #fa0;
  color: white;
}

.warning-dlg-button:hover, .warning-dlg-button:active, .warning-dlg-button:focus {
  color: white;
  background-color: #c4902e !important;
}

.primary-dlg-button {
  background-color: #20a8d8;
  box-shadow: 1px 1px 4px #00000038;
  transition: 0.2s;
  color: white;
}

.primary-dlg-button:hover, .primary-dlg-button:active, .primary-dlg-button:focus {
  color: white;
  box-shadow: none;
  background-color: #006b9f !important;
}

.hover-underline:hover {
  text-decoration: underline !important;
}

.card {
  border-radius: 16px;
  border: none;
  box-shadow: 0px 2px 4px #0004;
  margin-bottom: 12px;
}

tr, th, .fc table {
  border-color: #c8ced3;
  font-size: 0.94rem;
  line-height: 1.5rem;
}

.fc-timegrid-slots tr:nth-child(odd) {
  border-color: #f1f1f1;
}

.fc-timegrid-event, .fc-timegrid-more-link {
  border-radius: 0 !important;
}

.card-body {
  color: #23282c;
}

.dlg-calendar-card-container {
  padding: 0 12px;
}

.footer {
  display: none;
}

.points li::marker {
  display: none;
  color: transparent !important;
}

.admin-request ul.react-tabs__tab-list {
  padding-left: 0;
}

.admin-request ul.react-tabs__tab-list .l-h-35 {
  padding: 0 8px;
}

.dlg-tooltip {
  width: 1000px !important;
  min-width: max-content !important;
}

i:hover + .dlg-tooltip-fade {
  opacity: 1 !important;
  z-index: 2000;
}

i + .dlg-tooltip-fade {
  z-index: -1;
  transition: 0.2s ease-in;
}

.points.open {
  overflow: visible !important;
}

.step-number {
  border-width: 2px;
  border-color: #20a8d8;
  border-style: solid;
  border-radius: 50%;
  color: #5fa1e3;
  line-height: 14px;
  font-size: 12px;
  padding: 5px;
  transition: 0.5s;
  width: 30px;
  cursor: pointer;
  height: 30px;
  text-align: center;
  font-weight: bold;
}

.step-number.active {
  background-color: #20a8d8;
  border-radius: 50%;
  color: #fff;
  font-size: 12px;
  padding: 5px;
  width: 30px;
  height: 30px;
  text-align: center;
  font-weight: bold;
}

.width--38 {
  width: calc(100% - 28px);
}

.psicologo-planning tr {
  height: 40px;
}

.psicologo-planning .fc-timegrid-event.fc-v-event {
  min-height: 25px;
}

.hover-grey:hover {
  background-color: rgb(241, 241, 241);
}

.hover-shadow-none:hover {
  box-shadow: none !important;
}

.hover-darker:hover {
  background-color: rgb(255, 255, 255) !important;
}

.hover-icon-white:hover .icon-container {
  background-color: white !important;
}

.step-line {
  width: 300px;
  height: 2px;
  transition: 0.5s ease-in;
  background-color: #20a8d8;
}

.border-red-important, .border-red-important > .rw-widget-picker {
  border-color: red !important;
}

.force-error, .force-error > div {
  border-color: red !important;
}

.pagination .page-link {
  border-radius: 0 !important;
  color: #20a8d8;
}

.btn-link {
  color: #20a8d8 !important;
  text-decoration: none !important;
}

.btn-link:hover {
  text-decoration: underline !important;
}

.page-item.active .page-link, {
  background-color: #20a8d8 !important;
}

.form-check-input:checked {
  background-color: var(--cui-form-check-input-checked-bg-color, #20a8d8);
  border-color: var(--cui-form-check-input-checked-border-color, #20a8d8);
}

.list-group-item {
  margin-bottom: 0 !important;
}

a {
  color: #20a8d8;
}

.card-header:first-child {
  overflow: hidden;
  border: none;
}

.hover-darkness.card-header {
  border-radius: 16px !important;
}

.dlgListHeader {
  width: calc(100% + 45px);
  background-color: #f7f7f7;
  padding: 12px;
  font-weight: bold;
  box-shadow: -1px 1px 0px #b2b2b2;
  margin-bottom: 12px;
  margin-top: -16px;
  border-radius: 0px;
  position: sticky;
  top: -16px;
  z-index: 100;
  margin-left: -0px;
}

.mb-12px {
  margin-bottom: 12px !important;
}

.bg-green-important {
  background-color: #1d7731 !important;
}

//.dlgListHeader:first-child
.dlg-list-divider:first-child {
  display: none !important;
}

.btn-primary:focus, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #53799b;
  border-color: #151e27;
}

.dlg-top-link {
  background-color: white;
  color: #20a8d8 !important;
  text-decoration: none !important;
  margin: 6px;
  padding: 6px;
  border-radius: 16px;
  box-shadow: 0px 1px 3px rgba(2, 92, 114, 0.35);
  transition: 0.2s ease-in-out;
}

.dlg-top-link:hover, .dlg-top-link.active {
  text-decoration: underline !important;

}

#calendar-dlg > .fc {
  height: 100%;
}

.sidebar {
  box-shadow: #0000008c 1px 10px 10px;
}

.title-doc-send {
  width: calc(100% - 380px);
}

.child-flex-1 > * {
  flex: 1;
}

.child-d-flex-center > * {
  display: flex;
  align-items: center;
  justify-content: center;
}

.color-row {
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
}

.absolute-color-container {
  transition: 0.2s;
  background: white;
  padding: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.card {
  box-shadow: 0px 2px 6px rgba(2, 92, 114, 0.2) !important
}

.sidebar {
  box-shadow: rgba(2, 92, 114, 0.35) 1px 10px 10px !important;
}

.land-img {
  position: fixed;
  left: 0;
  top: 64px;
  bottom: 0;
  right: 0;
  object-fit: contain;
  height: calc(100% - 64px);
  width: 100%
}

.dlg-modal {
  position: fixed;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 3000;
  transition: 0.5s;
}

.dlg-pdf-toolbar {
  padding: 2px;
  padding-top: 3px;
  display: flex;
  justify-content: center;
}

.capacity-container {
  background: white;
  box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.21);
  border-radius: 12px;
  width: 100%;
  overflow: hidden;
  height: 5px;
}

.capacity-container > .capacity {
  box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.21);

}

.sidebar-nav a.nav-link {
  padding: 7px !important;
  border-radius: 12px;
  margin-left: 4px;
  margin-right: 4px;
  color: black !important;

}

.sidebar-nav {
  background: white !important;
}

a.nav-link.active {
  box-shadow: 2px 3px 8px rgba(2, 92, 114, 0.38);
  background: linear-gradient(45deg, #0f4458 0%, #2a7b9a 100%) !important;
  color: white !important;
}

.sidebar-nav .nav-group-items .nav-link {
  padding-left: calc(var(--cui-sidebar-nav-icon-width) - 5px) !important;
}

a.nav-link.active .nav-icon {
  color: white !important;
}

.sidebar-nav .nav-icon {
  color: black !important;
}

.sidebar-nav .nav-group {
  background: white !important;
}

.sidebar-nav .nav-group .nav-group-toggle {
  background: white !important;
  margin-top: 6px;
  color: black !important;
}

.sidebar-nav .nav-group .nav-group-toggle > * {
  color: #2a7b9a !important;
  font-weight: 600;

  transition: 0.2s;

}

.sidebar-nav .nav-item {
  font-size: 14px !important;
  margin: 6px 3px;
  transition: 0.2s;

}

.sidebar-nav .nav-group-toggle::after {
  filter: brightness(0);
  color: #000;
  transition: 0.2s;

}

.sidebar.visible-dlg .nav-icon {
  margin-right: -5px !important;
  transition: 0.2s;

}

.sidebar.not-visible-dlg .nav-icon {
  margin-left: -19px !important;
  transition: 0.2s;

}

.sidebar-toggler:hover::before {
  filter: brightness(0);
}

.sidebar-nav .nav-groups.inner-visible {
  overflow: visible;
}

.sidebar-nav .nav-group.inner-invisible {
  overflow: hidden;
}

.sidebar.not-visible-dlg .nav-group .nav-icon {
  margin-left: -65px !important;
}

.sidebar.not-visible-dlg .nav-link.nav-group-toggle {
  display: none;
}

.card-header {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-start-start-radius: 16px !important;
  border-start-end-radius: 16px !important;
}

.sme-button {
  position: absolute;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  padding: 0;
  bottom: 16px;
  right: 15px;
}

.btn-group {
  box-shadow: rgba(2, 92, 204, 0.3) 1px 1px 5px;
}

.btn {
  box-shadow: rgba(2, 92, 204, 0.3) 1px 1px 5px;

}

.btn-link {
  box-shadow: none
}

.planning-extra-button {
  position: fixed;
  align-items: center;
  z-index: 8;
  justify-content: center;
  bottom: 10px;
  background: white;
  padding: 6px;
  left: 50%;
  display: flex;
  transform: translateX(-50%);
  border-radius: 16px;
  min-width: max-content;
  max-width: max-content;
  width: max-content;
  box-shadow: 1px 0 5px rgba(32, 168, 216, 0.7) !important;
}
.md-visible{
  display: none;
}
.dlg-chat-title{
  text-transform: uppercase;
  font-weight: bold;
  font-size: 11px;
  color: dodgerblue;
}
@media(max-width: 1440px) {

  //.warning-planning {
  //  position: fixed;
  //  bottom: 24px;
  //  z-index: 20000000;
  //  background-color: #ffaaaa !important;
  //  right: 24px;
  //  width: 210px;
  //  text-align: center;
  //}
}

.student-planning-check, .student-planning-check label, .student-planning-check * {
  min-width: max-content;
}

.warning-planning {
  margin-left: 12px !important;
  font-size: 11px !important;
}

.warning-planning {
      background-color: #f005;
      padding: 6px 8px 4px;
      margin-bottom: 1px;
      margin-left: 12px;
      border-radius: 4px;
      font-size: 12px;
      font-weight: bold;
      border: 1px solid darkred;
      color: #000;
      text-transform: uppercase
}
@media(max-width: 970px) {

  .not-visible-dlg .navbar-brand .md-absolute-center {
    left: 19px;
    transform: none;
  }
  .header .navbar-nav .d-md-down-none .main-gradient {
    display: none;
  }
}


@media (max-width: 900px) {
  .md-flex-col{
    flex-direction: column;
  }
  .md-hidden {
    display: none;
  }
  .md-visible{
    display: block;
  }
  .fc .fc-toolbar-title {
    font-size: 1.25em;
    margin: 0;
  }
  .title-doc-send {
    width: 100%;
    padding-top: 50px;
  }
}

@media (max-width: 640px) {

  .fc .fc-col-header-cell-cushion{
    padding: 0;
  }
  .dlg-dayHeader{
    font-size: 11px;
  }
  #calendar-dlg .btn {
    font-size: 10px !important;

  }
  .report-title{
    font-size: 0.8rem;
  }
  .step-line{
    display: none !important;
  }
  .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr .btn[title='Oggi'] {
    display: none;
  }
}

@media (max-width: 576px) {
  .points h5{
    font-size: 1em;
  }
  .calendar-header.open-filter {
    min-height: 435px !important;
  }
  .calendar-header.open-filter.docente {
    min-height: 265px !important;
  }
  ul.ml-auto.navbar-nav {
    display: none !important;
  }
  .calendar-header  h3{
    font-size: 16px;
  }
  .view-report-button{
    position: absolute;
    margin-top: -100px;
    right: 0px;
  }
  .student-report-div{
    font-size: 12px !important;
  }
}
@media (max-width: 460px) {
  .dlg-appunti{
    //padding: 2px ;
    font-size: 8px !important;
  }
  .student-report-div{
    font-size: 11px !important;
  }

}
@media (max-width: 440px) {
  .dlg-appunti{
    position: relative;
    z-index: 2;
  }
  .planning-extra-button {

    width: 95% !important;
    min-width: min-content !important;

  }
  .sm-hidden{
    display: none !important;
  }
  .student-report-div{
    font-size: 10px !important;
  }
  .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr .fc-toolbar-chunk:first-child .btn-group {

    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    z-index: 1;
    box-shadow: none;
    margin-top: 50px;

  }
  .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr .fc-toolbar-chunk:first-child .btn-group .btn {
    max-width: 15px;
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 400px) {
  .dlg-top-link{
  font-size: 11px;
  }
  .view-report-button{
    font-size: 11.5px !important;
  }
  .points h5{
    font-size:0.90em;
  }
}
@media (max-width: 370px) {
  .dlg-appunti{
    padding: 2px ;
    }
  .dlg-dayHeader{
    font-size: 10px;
  }
  .report-title{
    font-size: 0.75rem;
  }
  .view-report-button{
    font-size: 10px !important;
  }
}

