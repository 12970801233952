// Variable overrides
$theme-colors: (
        "primary": #5fbfe3,
        "danger": #ff4136,
);
.btn{
  //--cui-button-bg:#0af !important;
}
.nav-link{
  --cui-sidebar-nav-link-color:#fff
}
.sidebar {
  --cui-sidebar-bg: #023a4f !important;
  --cui-card-color:#23282c !important;
}
:root{
  --main-gradient:linear-gradient(60deg, #5fbfe3, #0589bb)
}
.main-gradient{
  background: var(--main-gradient);
}
.modal-header{
  background: var(--main-gradient);
  color: white;
  text-align: center;
  justify-content: center !important;
}
.modal-header > *{
  font-weight: 600;
}
